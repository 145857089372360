import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from '../assets/logo/logo.js';

const Header = ({ siteTitle }) => (
  <header className={'header--wrapper'}>
    <div className={'header--container'}>
      <Link
        className={'header-hero--text'}
        to="/"
      >
        <Logo />
      </Link>
      <div className={'header-nav--container'}>
        <Link
          to="/media"
          className="header-nav--text"
        >
          Media
        </Link>
        <Link
          to="/submit"
          className="header-nav--text"
        >
          Submit
        </Link>
        <Link
          to="/shop"
          className="header-nav--text"
        >
          Shop
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
